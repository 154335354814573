import React, { useState, useEffect } from "react"
import { Checkbox } from "../Checkbox/Checkbox"
import styles from "./styles.module.scss"
import { getDocsData } from "../../../pages-data/_V2/common/commonData"
import {useLocalizationContext} from "../../../localization/useLocalizationContext";

interface Props {
	isAcceptedByDefault: boolean
	className?: string
	onCheckboxChange?: (isLegalAccepted: boolean) => void
}

export const ConsentCheckbox: React.FC<Props> = props => {
	const [isLegalAccepted, setIsLegalAccepted] = useState<boolean>(
		props.isAcceptedByDefault
	)

	useEffect(() => {
		if (props.onCheckboxChange) {
			props.onCheckboxChange(isLegalAccepted)
		}
	}, [isLegalAccepted])

	const localizationContext = useLocalizationContext()

	const docsData = getDocsData(localizationContext.locale)

	return (
		<Checkbox
			className={props.className}
			checkboxId="consentCheckbox"
			isChecked={isLegalAccepted}
			onCheck={setIsLegalAccepted}
			isDisabled={props.isAcceptedByDefault}
		>
			<p className={styles.legalText}>
				Я принимаю
				<a
					className={styles.legalLink}
					href={docsData.privacyPolicy.href}
					target="_blank"
				>
					{" "}
					условия обработки персональных данных
				</a>{" "}
				и
				<a
					className={styles.legalLink}
					href={docsData.lisenceAgreement.href}
					target="_blank"
				>
					{" "}
					условия лицензионного соглашения
				</a>
			</p>
		</Checkbox>
	)
}
