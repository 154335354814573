import classNames from "classnames"
import React, {
	Dispatch,
	SetStateAction,
	useState,
	useEffect,
	useRef,
} from "react"
import { ConsentCheckbox } from "../ConsentCheckbox/ConsentCheckbox"
import { PhoneInput } from "../PhoneInput/PhoneInput"
import { SimpleModal } from "../SimpleModal"
import Logo from "../../../assets/_V2/svg/Logo"
import styles from "./styles.module.scss"
import {
	requestForConsultation,
} from "../../../apiRequests/callToAction"
import { getPhoneValidState } from "../../../utils/validateFunctions"
import { consultFormSendEvent } from "../../../gtm-events"
import { TextInput } from "../TextInput"

interface Props {
	isOpen: boolean
	onClose: Dispatch<SetStateAction<boolean>>
	modalData: {
		customTitle?: JSX.Element
		title?: string | JSX.Element
		details?: string
		action: string
		successTitle: string
		successDetails: string
		successAction: string
		logo?: JSX.Element
	}
	inputTitle: string
	children?: React.ReactNode
	className?: string
	showBackdropShape: boolean
	withName?: boolean
	nameTitle?: string

	request?: Function
}

export enum ACTION_STATUSES {
	SENDING = "SENDING",
	NOT_SEND = "NOT_SEND",
	SUCCESS = "SUCCESS",
	FAIL = "FAIL",
}

type validityState = {
	valid: boolean
	message: string
}

export const RequestCallModal: React.FC<Props> = props => {
	const [actionStatus, setActionStatus] = useState<ACTION_STATUSES>(
		ACTION_STATUSES.NOT_SEND
	)
	const [isFormFilled, setIsFormFilled] = useState<boolean>(false)
	const [isSuccessView, setIsSuccessView] = useState<boolean>(false)

	const formDataInitial = {
		name: {
			value: "",
			errorMessage: "",
			isFilled: false,
		},
		phone: {
			value: "",
			errorMessage: "",
			isFilled: false,
		},
	}

	const [formData, setFormData] = useState(formDataInitial)

	useEffect(() => {
		if (!props.withName) {
			if (formData.phone.isFilled) {
				setIsFormFilled(true)
			} else {
				setIsFormFilled(false)
			}
			return
		}

		if (Object.values(formData).every(field => field.isFilled)) {
			setIsFormFilled(true)
		} else {
			setIsFormFilled(false)
		}
	}, [formData])

	useEffect(() => {
		if (!props.isOpen) {
			setFormData(formDataInitial)

			setActionStatus(ACTION_STATUSES.NOT_SEND)
			setIsSuccessView(false)
		}
	}, [props.isOpen])

	useEffect(() => {
		if (isSuccessView) {
			successFormBtn.current.focus()
		}
	}, [isSuccessView])

	const validateInput = (phone: string) => {
		return getPhoneValidState(phone)
	}

	const defaultErrors: {name: string, phone: string} = {
		name: "Введите корректное имя",
		phone: "Введите корректный номер телефона",
	}

	const handleInputChange = (
		value: string,
		isFilled: boolean,
		name: string,
		errorMessage: string,
	) => {
		if (!errorMessage) {
			errorMessage = defaultErrors[name]
		}
		setFormData({
			...formData,
			[name]: {
				errorMessage: !isFilled ? errorMessage : "",
				value,
				isFilled,
			},
		})
	}

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		setActionStatus(ACTION_STATUSES.SENDING)
		try {
			const inputValidityState: validityState = await validateInput(
				formData.phone.value
			)
			if (inputValidityState.valid) {
				if (props.request) {
					await props.request(
						props.withName
							? {
									phone: formData.phone.value,
									name: formData.name.value,
							  }
							: formData.phone.value
					)
				} else {
					await requestForConsultation(formData.phone.value)
				}
				setFormData({
					...formData,
					phone: {
						...formData.phone,
						errorMessage: "",
					},
				})
				setIsSuccessView(true)
				setActionStatus(ACTION_STATUSES.SUCCESS)

				consultFormSendEvent()
			} else {
				setFormData({
					...formData,
					phone: {
						...formData.phone,
						errorMessage: inputValidityState.message,
					},
				})
				setActionStatus(ACTION_STATUSES.NOT_SEND)
			}
		} catch (error) {
			// await errorAmoOffer()
			setFormData(formDataInitial)

			setActionStatus(ACTION_STATUSES.FAIL)
		}
	}

	const handleSubmitSuccess = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()

		props.onClose(!props.isOpen)
	}

	const successFormBtn = useRef()

	return (
		<SimpleModal
			isOpen={props.isOpen}
			onClose={props.onClose}
			isShowBackdropShape={props.showBackdropShape}
		>
			<section className={classNames(styles.content, props.className)}>
				{props.modalData.logo ? (
					props.modalData.logo
				) : (
					<Logo className={styles.logo} />
				)}
				{!isSuccessView ? (
					<div className={styles.wrapper}>
						{props.modalData.customTitle ? (
							props.modalData.customTitle
						) : (
							<h2 className={styles.title}>{props.modalData.title}</h2>
						)}

						{props.modalData.details && (
							<p className={styles.details}>{props.modalData.details}</p>
						)}

						<form className={styles.form} onSubmit={handleSubmit}>
							{props.withName && (
								<TextInput
									className={styles.nameInput}
									inputId="ctaName"
									inputTitle={props.nameTitle}
									value={formData.name.value}
									actionStatus={actionStatus}
									errorMessage={formData.name.errorMessage}
									onInputChange={(value, isInputFilled) =>
										handleInputChange(value, isInputFilled, "name")
									}
									extraProps={{
										required: true,
										autoFocus: true,
									}}
								/>
							)}

							<PhoneInput
								className={styles.phoneInput}
								inputId={"ctaPhone"}
								inputTitle={props.inputTitle}
								value={formData.phone.value}
								onInputChange={(value, isInputFilled) =>
									handleInputChange(value, isInputFilled, "phone")
								}
								actionStatus={actionStatus}
								errorMessage={formData.phone.errorMessage}
								isBig={!props.withName}
								extraProps={{
									required: true,
									autoFocus: !props.withName,
								}}
							/>

							<ConsentCheckbox
								className={styles.legalCheckbox}
								isAcceptedByDefault={true}
							/>

							<button
								className={styles.button}
								type="submit"
								disabled={
									!isFormFilled ||
									actionStatus === ACTION_STATUSES.SUCCESS ||
									actionStatus === ACTION_STATUSES.SENDING
								}
							>
								{props.modalData.action}
							</button>
						</form>
					</div>
				) : (
					<div className={classNames(styles.wrapper, styles.wrapperSuccess)}>
						<h2 className={styles.title}>{props.modalData.successTitle}</h2>
						<p className={styles.details}>{props.modalData.successDetails}</p>

						<form className={styles.successForm} onSubmit={handleSubmitSuccess}>
							<button
								ref={successFormBtn}
								className={styles.button}
								type="submit"
							>
								{props.modalData.successAction}
							</button>
						</form>
					</div>
				)}
			</section>
		</SimpleModal>
	)
}
