import classNames from "classnames"
import React, { useState, useEffect } from "react"
import InputMask from "react-input-mask"
import styles from "./styles.module.scss"
import ValidityOkIcon from "../../../assets/_V2/svg/ValidityOkIcon"
import { ACTION_STATUSES } from "../RequestCallModal/RequestCallModal"
import {useLocalizationContext} from "../../../localization/useLocalizationContext";
import {Locales} from "../../../localization/types";

interface Props {
	inputId: string
	className?: string
	inputTitle?: string
	value: string
	actionStatus: ACTION_STATUSES
	errorMessage: string
	successMessage?: string
	onInputChange: (value: string, isInputFilled: boolean) => void
	extraProps: { [key: string]: string | boolean }
	isBig?: boolean
}

enum MASK_SWITCHER_OPTIONS {
	RU = "У меня российский номер",
	BY = "У меня белорусский номер",
	WW = "У меня номер другой страны",
}

enum PHONE_NUMBER_VALUE_MAX_LENGTH {
	// Длина строки — плюс и цифры. Для RU скобки, пробелы и дефисы не считаются
	RU = 12,
	WW = 16,
	WWMIN = 12,
}

enum PHONE_MASKS_BY_LOCALE {
	DEFAULT = '+7 (999) 999-99-99',
	'ru-BY' = '+375 (99) 999-99-99'
}

enum PHONE_PLACEHOLDER_BY_LOCALE {
	DEFAULT = '+7 (800) 123-45-67',
	'ru-BY' = '+375 (12) 345-67-89'
}

export const PhoneInput: React.FC<Props> = props => {
	const { locale } = useLocalizationContext();
	const getPhoneMask = (locale: Locales) => {
		return PHONE_MASKS_BY_LOCALE[locale as keyof typeof PHONE_MASKS_BY_LOCALE] || PHONE_MASKS_BY_LOCALE.DEFAULT;
	}

	const getPlaceholderByLocale = (locale: Locales) => {
		return PHONE_PLACEHOLDER_BY_LOCALE[locale as keyof typeof PHONE_PLACEHOLDER_BY_LOCALE] || PHONE_PLACEHOLDER_BY_LOCALE.DEFAULT
	}

	const initialPlaceholder = getPlaceholderByLocale(locale)

	const [isRuPhoneNumber, setIsRuPhoneNumber] = useState<boolean>(true)
	const [isWWFocused, setIsWWFocused] = useState<boolean>(false)
	const [isInputFocused, setIsInputFocused] = useState<boolean>(false)
	const [placeholder, setPlaceholder] = useState<string>(initialPlaceholder)
	const [showTitle, setShowTitle] = useState(false)

	// Показываем title, если есть автофокус
	useEffect(()=> {
		if (props.extraProps?.autoFocus) {
			setShowTitle(true)
		}
	}, [])

	const checkIfInputFilled = (inputValue: string) => {
		const fullLength = isRuPhoneNumber
			? PHONE_NUMBER_VALUE_MAX_LENGTH.RU
			: PHONE_NUMBER_VALUE_MAX_LENGTH.WWMIN

		return inputValue.length >= fullLength
	}

	const trimNonDigits = (value: string) => {
		return "+" + value.slice(1).replace(/[^+\d]/g, "")
	}

	const switchMaskCountry = () => {
		setIsInputFocused(true)

		setIsRuPhoneNumber(!isRuPhoneNumber)
		props.onInputChange("", false)

		setShowTitle(true)
	}

	const handleInputChange = (value: string, maskedValue?: string) => {
		if (maskedValue) {
			setPlaceholder(maskedValue + initialPlaceholder.slice(maskedValue.length))
		}

		const isFilled = checkIfInputFilled(trimNonDigits(value))

		props.onInputChange(value, isFilled)

		value.length >= 0 ? setShowTitle(true) : setShowTitle(false)
	}

	const onBlur = (value: string) => {
		setIsWWFocused(false)
		setIsInputFocused(false)

		const isFilled = checkIfInputFilled(trimNonDigits(value))

		if (isRuPhoneNumber && !value.length) {
			props.onInputChange("", false)
		}

		if (!isFilled && value.length) {
			props.onInputChange(value, false)
		}

		value.length === 0 && setShowTitle(false)
	}

	return (
		<>
			<label
				key={locale}
				htmlFor={props.inputId}
				className={classNames(styles.input, props.className, {
					[styles.inputFilled]: props.value.length,
				})}
			>
				<div
					className={classNames(styles.inputContainer, {
						[styles.inputContainer_error]:
						props.errorMessage && !isInputFocused,
						[styles.inputContainer_big]: props.isBig,
					})}
				>
					<div className={styles.inputWithTitle}>
						{props.inputTitle && showTitle && (
							<p className={styles.inputTitle}>{props.inputTitle}</p>
						)}
						{isRuPhoneNumber ? (
							<>
								<input
									className={styles.phonePlaceholder}
									value={placeholder}
								/>
								<InputMask
									className={styles.inputField}
									type="tel"
									id={props.inputId}
									placeholder={props.inputTitle || ""}
									mask={getPhoneMask(locale)}
									maskChar={null}
									value={props.value}
									onChange={event => {
										handleInputChange(
											trimNonDigits(event.target.value),
											event.target.value
										)
									}}
									onBlur={event => {
										if (event.relatedTarget?.dataset?.name === 'close' || !event.relatedTarget) {
											return
										}

										onBlur(event.target.value)
									}}
									onFocus={() => {
										setIsInputFocused(true)
										setIsRuPhoneNumber(true)
									}}
									disabled={
										props.actionStatus === ACTION_STATUSES.SENDING ||
										props.actionStatus === ACTION_STATUSES.SUCCESS
									}
									{...props.extraProps}
								/>
							</>
						) : (
							<input
								className={styles.inputField}
								type="tel"
								id={props.inputId}
								placeholder={props.inputTitle || ""}
								value={isWWFocused ? trimNonDigits(props.value) : props.value}
								maxLength={PHONE_NUMBER_VALUE_MAX_LENGTH.WW}
								onChange={event => {
									handleInputChange(trimNonDigits(event.target.value))
								}}
								onFocus={() => {
									setIsInputFocused(true)
									setIsWWFocused(true)
								}}
								onBlur={event => onBlur(event.target.value)}
								disabled={
									props.actionStatus === ACTION_STATUSES.SENDING ||
									props.actionStatus === ACTION_STATUSES.SUCCESS
								}
								{...props.extraProps}
							/>
						)}
					</div>
					{!props.errorMessage &&
						checkIfInputFilled(trimNonDigits(props.value)) && (
							<div
								className={classNames(styles.icon, styles.icon_type_trailing)}
							>
								<ValidityOkIcon />
							</div>
						)}
				</div>

				{props.errorMessage && !isInputFocused && (
					<p
						className={classNames(
							styles.inputHelperText,
							styles.inputHelperText_type_error
						)}
					>
						{props.errorMessage}
					</p>
				)}

				{props.successMessage && <p>{props.successMessage}</p>}

				<button
					className={classNames(
						styles.inputHelperText,
						styles.inputHelperText_type_button,
						{ [styles.inputHelperText_type_buttonVisible]: isInputFocused }
					)}
					onMouseUp={switchMaskCountry}
					onFocus={() => {
						setIsInputFocused(true)
					}}
					onBlur={() => {
						setIsInputFocused(false)
					}}
					type={"button"}
				>
					{isRuPhoneNumber ? MASK_SWITCHER_OPTIONS.WW : (locale === 'ru-BY' ? MASK_SWITCHER_OPTIONS.BY : MASK_SWITCHER_OPTIONS.RU)}
				</button>
			</label>
		</>
	)
}
