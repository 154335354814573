import React, { SVGProps } from "react"

export const CheckIcon = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			width={14}
			height={10}
			viewBox="0 0 14 10"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M12.25 1.8125C10.8731 3.18936 6.78311 7.27939 5.40625 8.65625L1.75 5"
				stroke="white"
				strokeWidth={1.875}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	)
}
