import { useEffect } from 'react'


export function useDisableHtmlScroll(disable : boolean) : void {
    useEffect(() => {
        const defaultBodyOverflow = 'auto'
        
        document.documentElement.style.overflow = disable
            ? 'hidden'
            : defaultBodyOverflow
        
        return () => {
            document.documentElement.style.overflow = defaultBodyOverflow
        }
    }, [disable])
}
