import * as React from "react"

const ModalBackdropShape = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		viewBox="0 0 375 226"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		{...props}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M375 180.441V0.000396729H86.4854C255.434 14.7892 358.774 92.3606 375 180.441Z"
			fill="url(#paint0_linear_4665_108293)"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M375 180.441V0.000396729H86.4854C255.434 14.7892 358.774 92.3606 375 180.441Z"
			fill="url(#pattern0)"
			fillOpacity={0.5}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M375 180.441V0.000396729H86.4854C255.434 14.7892 358.774 92.3606 375 180.441Z"
			fill="url(#paint1_linear_4665_108293)"
			fillOpacity={0.7}
		/>
		<defs>
			<pattern
				id="pattern0"
				patternContentUnits="objectBoundingBox"
				width={0.0693205}
				height={0.11361}
			>
				<use
					xlinkHref="#image0_4665_108293"
					transform="scale(0.00173301 0.00277099)"
				/>
			</pattern>
			<linearGradient
				id="paint0_linear_4665_108293"
				x1={375}
				y1={192}
				x2={80.5445}
				y2={23.5177}
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#609FFF" />
				<stop offset={0.5} stopColor="#7175F2" />
				<stop offset={1} stopColor="#A26AEB" />
			</linearGradient>
			<linearGradient
				id="paint1_linear_4665_108293"
				x1={375}
				y1={192}
				x2={80.5445}
				y2={23.5177}
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#609FFF" />
				<stop offset={0.5} stopColor="#7175F2" />
				<stop offset={1} stopColor="#A26AEB" />
			</linearGradient>
			<image
				id="image0_4665_108293"
				width={40}
				height={41}
				xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAApCAYAAABHomvIAAAAf0lEQVRYCe3SwQnAIBBE0ek1ahuxg9iLbFKWJSRIPAi5eBIhX1jw4OL4XImFAAIIIIAAAggggAACiwgEXbuTlVpB57FIrDdGDeRld19BFpcJ6WWlD9f2hYCjAk4Wv4I5jfZPObcpp/bVxSmvM39TXs8lCCCAAAIIIIAAAr8UeAAiHz7ihAEDdwAAAABJRU5ErkJggg=="
			/>
		</defs>
	</svg>
)

export default ModalBackdropShape
