import * as React from "react"
import { SVGProps } from "react"

const ValidityOkIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width={24}
		height={24}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M19 7.75C17.1642 9.58581 11.7108 15.0392 9.875 16.875L5 12"
			stroke="#1BC595"
			strokeWidth={2.5}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default ValidityOkIcon
