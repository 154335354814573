import classNames from "classnames"
import React, { ReactElement } from "react"
import { CheckIcon } from "./assets/CheckIcon"
import styles from "./styles.module.scss"

interface Props {
	checkboxId: string
	isChecked: boolean
	onCheck: (isChecked: boolean) => void
	isDisabled: boolean
	children?: ReactElement<any, any>
	className?: string
}

export const Checkbox: React.FC<Props> = props => {
	return (
		<label
			className={classNames(styles.checkbox, props.className)}
			htmlFor={props.checkboxId}
		>
			<input
				className={styles.checkboxInput}
				type="checkbox"
				id={props.checkboxId}
				disabled={props.isDisabled}
				checked={props.isChecked}
				onChange={event => {
					props.onCheck(event.target.checked)
				}}
			/>
			<span className={styles.checkboxSwitch}>
				{props.isChecked && <CheckIcon className={styles.checkboxIcon} />}
			</span>

			{props.children}
		</label>
	)
}
