import * as React from "react"
import { SVGProps } from "react"

const CloseBigWhite = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width={24}
		height={24}
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="m5 5 14 14M19 5 5 19"
			stroke="#fff"
			strokeWidth={2}
			strokeLinecap="round"
		/>
	</svg>
)

export default CloseBigWhite
