import React, { Dispatch, SetStateAction } from "react"
import Modal from "react-modal"
import classNames from "classnames"

import { useDisableHtmlScroll } from "../../../hooks/_V2/useDisableHtmlScroll"

import styles from "./styles.module.scss"
import CloseBigWhite from "./assets/CloseBigWhite"
import ModalBackdropShape from "./assets/ModalBackdropShape"

interface Props {
	isOpen: boolean
	onClose: Dispatch<SetStateAction<boolean>>
	children: React.ReactNode
	isShowBackdropShape: boolean
}

export const SimpleModal: React.FC<Props> = props => {
	useDisableHtmlScroll(props.isOpen)

	return (
		<Modal
			isOpen={props.isOpen}
			onRequestClose={() => {
				props.onClose(!props.isOpen)
			}}
			ariaHideApp={false}
			overlayClassName={styles.modalOverlay}
			className={styles.modalContainer}
		>
			<button
				type="button"
				data-name="close"
				className={classNames(styles.closeButton, {
					[styles.closeButtonBlack]: !props.isShowBackdropShape,
				})}
				onClick={() => {
					props.onClose(!props.isOpen)
				}}
			>
				<CloseBigWhite className={styles.crossIcon} />
			</button>

			{props.children}

			{props.isShowBackdropShape && (
				<ModalBackdropShape className={styles.modalBackdropShape} />
			)}
		</Modal>
	)
}
